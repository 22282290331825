.Page {
    width: 100vw;
    height: 100vh;
    
    display: block;
    background: var(--background);
}

.Page--out {
    position: absolute;
    display: block;
    animation: page__out 250ms cubic-bezier(.25,.99,.99,1);
    animation-fill-mode: forwards;
}

.Page--active {
    position: absolute;
    display: block;
    animation: page__in 500ms cubic-bezier(.25,.99,.99,1);
}

@keyframes page__in {
    from, 50% {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes page__out {
    to {
        opacity: 0;
        transform: scale(0.9) translateY(-120px);
        display: none;
    }

    20% {
        opacity: 1;
    }

    from {
        opacity: 1;
        transform: scale(1) translateY();
        display: block;
    }
}