.Header {
    display: flex;
    margin: 10px 0;
    width: 100%;

    font-weight: bold;

    justify-content: space-between;
    align-items: baseline;
}

.Header--level-1 {
    font-size: 24px;
}

.Header--level-2 {
    font-size: 20px;
}

.Header--level-3 {
    font-size: 16px;
}

.Header--uppercase {
    text-transform: uppercase;
}

