.Container {
    width: 100%;
    max-width: var(--content-max-width);
    margin: 0 auto;

    padding: 20px;
    box-sizing: border-box;

    position: relative;
    z-index: 10;
}