.Input {
    position: relative;
}

.Input__element {
    font-family: var(--font-family-primary);
    font-size: 14px;
    line-height: 16px;
    padding: 8px 10px;

    background: var(--input-bg);
    border: 1px solid var(--border-color);
    border-radius: 5px;

    outline: none;
    width: 100%;
    box-sizing: border-box;
}

.Input--with-counter .Input__element {
    padding-right: 35px;
}

.Input__element:focus {
    border: 1px solid var(--accent);
    box-shadow: 0px 0px 4px var(--accent);
}

.Input__element:invalid {
    color: var(--red);
    border: 1px solid var(--red);
    box-shadow: none;
}

.Input__notice {
    color: var(--red);
    font-size: 14px;
    display: none;
}

.Input__element:invalid ~ .Input__notice {
    display: block;
}

.Input__counter {
    font-size: 14px;
    color: var(--hint-color);
    position: absolute;
    top: 8px;
    right: 10px;
    text-align: right;
}