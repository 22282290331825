.Router {
    width: 100vw;
    height: 100vh;

    position: relative;
}

.Router__popout {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    z-index: 100;
}