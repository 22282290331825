.FullPagePlaceholder {
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
}

.FullPagePlaceholder__container {
    text-align: center;
}

.FullPagePlaceholder__icon {
    width: 120px;
    height: 120px;

    margin: 0 auto;

    background-size: contain !important;
    background-position: center !important;
}

.FullPagePlaceholder__title {
    font-size: 24px;
    font-weight: bold;
}

.FullPagePlaceholder__text {
    font-size: 18px;
}