.Board {
    white-space: nowrap;
    overflow-x: scroll;
    padding: 0 20px;
}

.Board__actions {
    position: fixed;
    top: 10px;
    right: 10px;

    z-index: 20;
}