.OrderActionsMask {
    width: 100vw;
    height: 100vh;

    background: var(--popout-mask-background);
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.OrderActions {
    background: var(--background);
    padding: 20px;

    width: 500px;
    max-width: 100%;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    row-gap: 0px;
}

.OrderActions .Button {
    margin: 0;
}

.OrderActions .Button:not(:last-child) {
    margin: 0;
    margin-bottom: 10px;
}