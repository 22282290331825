.OrderItem {
    width: 200px;
    min-height: 200px;
    padding: 10px;
    padding-bottom: 50px;
    box-sizing: border-box;
    margin-bottom: 10px;

    border-radius: 5px;
    color: var(--text-color-invert);
    cursor: pointer;

    position: relative;
    overflow: hidden;
}

.OrderItem__timer {
    opacity: 0;
    text-align: right;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.OrderItem--DISH_TIMEOUT .OrderItem__timer,
.OrderItem--DISH_ACCEPTED_TIMEOUT .OrderItem__timer {
    opacity: 1;
}


.OrderItem__timer--visible {
    opacity: 1;
}

.OrderItem__timer--shake {
    animation: timer_shake 1s linear infinite;
}

@keyframes timer_shake {
    0%, 50% {
        opacity: 1;
    }

    51%, to {
        opacity: 0.6;
    }
}

.OrderItem__timer {
    font-size: 24px;
    font-weight: bold;
    height: 1.2em;
}

.OrderItem--DISH_NEW {
    background: var(--bright-blue);
}

.OrderItem--DISH_TIMEOUT,
.OrderItem--DISH_ACCEPTED_TIMEOUT {
    background: var(--bright-red);
}

.OrderItem--DISH_ACCEPTED {
    background: var(--bright-orange);
}

.OrderItem--DISH_READY {
    background: var(--bright-green);
}

.OrderItem__title {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 10px;
    white-space: pre-line;
}

.OrderItem__text {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: pre-line;
}

.OrderItem--DISH_NEW .OrderItem__title,
.OrderItem--DISH_NEW .OrderItem__text,
.OrderItem--DISH_TIMEOUT .OrderItem__title,
.OrderItem--DISH_TIMEOUT .OrderItem__text {
    opacity: 0;
}

.OrderItem--DISH_READY .OrderItem__timer,
.OrderItem--DISH_READY .OrderItem__timer--visible {
    display: none;
}