:root {
    --default-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

    --background: #FFFFFF;
    --text-color: #111111;

    --text-color-invert: #FFFFFF;
    --hint-color: #AAAAAA;
    --button-text-color: #FFFFFF;
    /* --accent: #8774e1; */
    --link-color: #8774e1;

    --accent: #FF51A6;

    --border-color: #DDDDDD;
    --shadow-1: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

    --content-max-width: 1500px;
    --image-placeholder: rgba( 0, 0, 0, 0.2 );

    --ripple-color: rgba( 0, 0, 0, 0.3 );
    --ripple-timing-function: cubic-bezier(.17,.67,.54,.98);
    --ripple-duration: 600ms;

    --orange: rgb(255, 153, 0);
    --green: rgb(34, 138, 49);
    --red: rgb(186, 64, 64);

    --bright-blue: #4200FF;
    --bright-red: #FF0000;
    --bright-green: #0EB10A;
    --bright-orange: #FF8A00;

    --input-bg: #F0F0F0;
    --input-hover: #DDDDDD;
    --input-icon: #AAAAAA;



    

    --placeholder-solid-background: var(--hint-color);
    --placeholder-background: linear-gradient(90deg, var(--hint-color) 0%,
                                                     var(--hint-color) 25%,
                                                     var(--text-color) 50%,
                                                     var(--hint-color) 75%,
                                                     var(--hint-color) 100%);
    --placeholder-opacity: 0.15;
    --placeholder-animation: skeleton 2s linear infinite;

    --popout-mask-background: rgba( 0, 0, 0, 0.5 );
}