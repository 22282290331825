.Auth {
    display: flex;
    align-items: center;
    justify-content: center;

    background: url('../../images/login.jpeg') no-repeat;
    background-size: cover;
    background-position: center;
}

.Auth__content {
    background: var(--background);
    padding: 20px;

    width: 320px;
    max-width: 100%;
    border-radius: 10px;
}

.Auth__content .Input {
    margin-bottom: 10px;
}