.Ripple {
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;

    overflow: hidden;
    display: inline-block;

    cursor: pointer;
}

.Ripple__content {
    position: relative;
    z-index: 1;
}

.Ripple__container {
    z-index: 1;
}

.Ripple__item {
    position: absolute;

    top: 10px;
    left: 35px;

    width: 600px;
    height: 600px;

    margin: -300px;

    background: var(--ripple-color);
    border-radius: 50%;

    animation: ripple var(--ripple-duration) var(--ripple-timing-function);
    animation-fill-mode: forwards;
}

@keyframes ripple {
    from {
        transform: scale(0);
        opacity: 1;
    }

    to {
        transform: scale(1);
        opacity: 0;
    }
}
