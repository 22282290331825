.OrderBox {
    display: inline-block;
    position: relative;

    margin-right: 40px;
    animation: orderbox_in 0.3s ease;
}

.OrderBox__content {
    display: flex;

    column-gap: 10px;
    row-gap: 0px;

    flex-wrap: wrap;
    flex-direction: column;
    
    height: calc(100vh - 100px);

    position: relative;
    padding-top: 60px;
}

.OrderBox__num {
    font-size: 40px;
    font-weight: bold;
    margin-right: 10px;
}

.OrderBox__actions {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;

    display: flex;
    align-items: center;
    column-gap: 10px;;
}

@keyframes orderbox_in {
    from {
        transform: translateX(100px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}