@keyframes skeleton {
    from {
        background-size: 400%;
        background-position: 100%;
    }

    to {
        background-size: 400%;
        background-position: 0%;
    }
}