.ModalMask {
    width: 100vw;
    height: 100vh;

    background: var(--popout-mask-background);
    
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: scroll;
}

.Modal {
    background: var(--background);
    padding: 20px;
    
    max-height: 95vh;
    width: 500px;
    max-width: 100%;
    border-radius: 10px;

    overflow: scroll;
}