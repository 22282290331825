html,
body {
    padding: 0;
    margin: 0;

    width: 100vw;
    height: 100vh;

    font-family: var(--default-font);
    -webkit-tap-highlight-color: transparent;
}

body {
    background: var(--background);
    color: var(--text-color);
}

a {
    color: var(--link-color);
}

*::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    background: none;
}

* {
    user-select: none;
    -webkit-user-select: none;
}

#root {
    width: 100vw;
    height: 100vh;
}