.Button {
    display: inline-flex;
    position: relative;

    justify-content: center;
    align-items: center;

    background: var(--accent);
    color: var(--button-text-color);

    text-decoration: none;
    font-family: var(--default-font);
    font-size: 14px;
    font-weight: bold;

    border-radius: 5px;
    border: none;
    outline: none;

    cursor: pointer;
    text-align: center;

    margin: 5px 0;
    transition: transform 0.1s linear;
}

.Button:hover {
    text-decoration: none;
    opacity: 0.95;
}

.Button:focus {
    outline: none;
}

.Button--active,
.Button:active {
    transform: scale( 0.95 );
    transition: transform 0.1s linear;
}


.Button--s {
    padding: 5px 12px;
}

.Button--m {
    padding: 8px 20px;
}

.Button--l {
    padding: 10px 25px;
    font-size: 16px;
    margin: 10px 0;
}

.Button--wide {
    width: 100%;
}

.Button--secondary {
    background: var(--background);
    color: var(--text-color);
    border: 0.5px solid var(--border-color);
    box-shadow: var(--shadow-1);
}

.Button--tertiary {
    background: transparent;
    color: var(--accent);
}

.Button--destructive {
    background: var(--red);
    color: var(--button-text-color);
}


.Button--uppercase {
    text-transform: uppercase;
}

.Button--loading:after {
    content: '';
    display: inline-block;

    width: 0.8em;
    height: 0.8em;

    margin-left: 5px;

    border: 2px solid transparent;
    border-top: 2px solid currentColor;
    border-right: 2px solid currentColor;
    border-radius: 50%;

    animation: button_spinner 1s linear infinite;
}


@keyframes button_spinner {
    from {
        transform: rotateY(0);
    }

    to {
        transform: rotateZ(360deg);
    }
}
